import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Seo from '../components/seo'
import { Columns, Container, Element, Heading, Section } from 'react-bulma-components'
import { Link } from 'gatsby-plugin-react-i18next'
import LoginForm from '../components/login-form/login-form'
import { useTranslation } from 'gatsby-plugin-react-i18next'

const LoginPage = () => {
  const { t } = useTranslation()
  return (
    <Layout anonymous>
      
      <Section size='medium'>
        <Container>
          <Columns centered gap={8} className='has-border-right'>
            <Columns.Column size={4}>
              <Heading size={5}>
                {t('login:title')}
              </Heading>
              <LoginForm />
            </Columns.Column>
            <Columns.Column size='4'>
              <Heading size={5}>
                {t('login:register_title')}
              </Heading>
              <Element
                renderAs='p'
                mb={1}
              >
                {t('login:pro_register')}
              </Element>
              <Element
                renderAs='p'
                mb={5}
              >
                <Element
                  renderAs={Link}
                  className='exalto-link'
                  to='/sign-up-as-pro'
                >
                  <Element renderAs='span' textColor='primary'>→</Element> {t('login:pro_register_link')}
                </Element>
              </Element>
              { /*<Element
                renderAs='p'
                mb={1}>
                {t('login:customer_register')}
              </Element>
              <Element
                mb={5}
                renderAs='p'
              >
                <Element
                  renderAs={Link}
                  className='exalto-link'
                >
                  <Element renderAs='span' textColor='primary'>→</Element> {t('login:customer_register_link')}
                </Element>
              </Element>*/ }
            </Columns.Column>
          </Columns>
        </Container>
      </Section >
    </Layout >
  )
}
export default LoginPage

export const query = graphql`
  query($language: String!) {
          locales: allLocale(filter: {language: {eq: $language}}) {
          edges {
          node {
          ns
          data
          language
        }
      }
    }
  }
`