import React from 'react'
import { useSessionRequest } from '../../stores/session'
import { RequestMessage } from '../../stores/utils'
import { Button, Element, Form } from 'react-bulma-components'
import { Link } from 'gatsby-plugin-react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShieldAlt, faUser } from '@fortawesome/free-solid-svg-icons'
import { useForm } from 'react-hook-form'
import FormField, { HookFormWrapper } from '../form/form-field'
import { useTranslation, Trans } from 'gatsby-plugin-react-i18next'

const LoginForm = () => {
  const [request, actions] = useSessionRequest({ key: 'post /auth' })
  const { control, handleSubmit } = useForm({
    shouldUseNativeValidation: true
  })
  const onSubmit = data => {
    actions.login(data)
  }
  const { t } = useTranslation()
  return (
    <>
      <RequestMessage
        request={ request }
        disabled={ ['success', 'inProgress'] }
        messages={{
          error: <span>
            {
              request && request.data && request.data.data.message && request.data.data.message === 'Organization not active' &&
                <span>
                  <Trans i18nKey='login.errors.organization_not_validated' />
                  <br /><br />
                  {t('forms:errors.contact_us.1')} {t('forms:errors.contact_us.2')} {t('forms:errors.contact_us.3')}
                </span>
            }
            {
              request && request.message === 'Incorrect email or password' &&
                <span>
                  {t('login:errors.incorrect_email_or_password')}
                </span>
            }
          </span>
        }}

      />
      <form onSubmit={handleSubmit(onSubmit)}>

        <FormField label={t('forms:contact.email')} horizontal={false}>
          <HookFormWrapper
            name='email'
            control={ control }
            icon={ <FontAwesomeIcon icon={faUser} /> }
            placeholder='email...'
            rules={ { required: { value: true, message: t('forms:validations.required_field') } } }
          />
        </FormField>
        <FormField label={t('forms:contact.password')} horizontal={false}>
          <HookFormWrapper
            name='password'
            control={ control }
            icon={ <FontAwesomeIcon icon={faShieldAlt} /> }
            placeholder={ `${t('forms:contact.password')}...` }
            type='password'
            rules={ { required: { value: true, message: t('forms:validations.required_field') } } }
          />
        </FormField>
        <Form.Field>
          <Form.Control>
            <Button
              type='submit'
              color='primary'
            >
              {t('login:submit')}
            </Button>
          </Form.Control>
        </Form.Field>
        <Element
          mb={5}
          renderAs='p'
        >
          <Element
            renderAs={Link}
            className='exalto-link'
            textSize={7}
            to='/reset-password'
          >
            <Element renderAs='span' textColor='primary'>→</Element> {t('login:password_forgotten')}
          </Element>
        </Element>
      </form>
    </>
  )
}

export default LoginForm